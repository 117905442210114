import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { UnorderedList, OrderedList, ListItem } from '../List';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "list"
    }}>{`List`}</h1>
    <h2 {...{
      "id": "unorderedlist"
    }}>{`UnorderedList`}</h2>
    <p>{`The UnorderedList is used to indicate a list of equally important items. It can be turned into a check list by adding props 'checked' or 'unchecked' to the ListItem inside.`}</p>
    <h3 {...{
      "id": "props-unorderedlist"
    }}>{`Props UnorderedList`}</h3>
    <Props of={UnorderedList} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`The main props to set are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`variant`}</strong>{` - one of `}<inlineCode parentName="li">{`['bullet', 'checked', 'unchecked']`}</inlineCode>{`, defaults to 'bullet'`}</li>
    </ul>
    <Playground __position={1} __code={'<UnorderedList>\n  <ListItem>Tell me something</ListItem>\n  <ListItem>Make me smile</ListItem>\n  <ListItem>Make me laugh out loud</ListItem>\n</UnorderedList>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      UnorderedList,
      OrderedList,
      ListItem,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UnorderedList mdxType="UnorderedList">
    <ListItem mdxType="ListItem">Tell me something</ListItem>
    <ListItem mdxType="ListItem">Make me smile</ListItem>
    <ListItem mdxType="ListItem">Make me laugh out loud</ListItem>
  </UnorderedList>
    </Playground>
    <h2 {...{
      "id": "check-lists"
    }}>{`Check Lists`}</h2>
    <p>{`Add `}<inlineCode parentName="p">{`variant="checked"`}</inlineCode>{` and `}<inlineCode parentName="p">{`variant="unchecked"`}</inlineCode>{` to the `}<inlineCode parentName="p">{`ListItem`}</inlineCode>{` to turn the list into a checklist.
You can use `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` handlers to change state and make the check list interactive.`}</p>
    <Playground __position={2} __code={'<UnorderedList>\n  <ListItem variant=\"checked\">Tell me something</ListItem>\n  <ListItem variant=\"checked\">Make me smile</ListItem>\n  <ListItem variant=\"unchecked\">Make me laugh out loud</ListItem>\n</UnorderedList>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      UnorderedList,
      OrderedList,
      ListItem,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UnorderedList mdxType="UnorderedList">
    <ListItem variant="checked" mdxType="ListItem">Tell me something</ListItem>
    <ListItem variant="checked" mdxType="ListItem">Make me smile</ListItem>
    <ListItem variant="unchecked" mdxType="ListItem">Make me laugh out loud</ListItem>
  </UnorderedList>
    </Playground>
    <h2 {...{
      "id": "orderedlist"
    }}>{`OrderedList`}</h2>
    <p>{`Use just as you would use a normal ordered list. Currently OrderedLists will have decimal numerals and always start at 1.`}</p>
    <h3 {...{
      "id": "props-orderedlist"
    }}>{`Props OrderedList`}</h3>
    <Props of={UnorderedList} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <Playground mdxType="Playground">
  <OrderedList mdxType="OrderedList">
    <ListItem mdxType="ListItem">Tell me something</ListItem>
    <ListItem mdxType="ListItem">Make me smile</ListItem>
    <ListItem mdxType="ListItem">Make me laugh out loud</ListItem>
  </OrderedList>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      